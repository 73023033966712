<template>
  <div class="dropdown-wrapper max-w-full">
    <!-- <p class="text-green label" :class="{ required: required }">{{ label }}</p> -->
    <div
      class="dropdown-title"
      @click="showDropdown = true"
      ref="toggle"
      :class="showDropdown ? 'no-border-radius' : ''"
    >
      <input
        type="text"
        v-model="termForFilter"
        @focus="showDropdown = true"
        @keypress="showDropdown = true"
        :placeholder="selectTitle + (required && value.length < 1 ? ' *' : '')"
        @keyup.enter="
          filteredData.length > 0
            ? selectOption(
                filteredData[0][optionToSave],
                filteredData[0][optionToDisplay]
              )
            : ''
        "
        autocomplete="no-fill"
      />
      <div
        class="icon"
        :class="{ rotate: showDropdown }"
        @click.stop="doSomething()"
      >
        <Icon height="25" icon="mdi:chevron-down-circle-outline" />
      </div>
    </div>
    <div class="dropdown-conent" v-if="showDropdown">
      <ul>
        <li
          class="dropdown-list-item"
          v-for="(val, index) in filteredData"
          :key="index"
          @click="selectOption(val[optionToSave], val[optionToDisplay], val)"
        >
          <div class="dropdown-list-item-name">
            {{ val[optionToDisplay] }}
          </div>
        </li>
      </ul>
    </div>
    <div class="custom-input__error-message">
      <transition name="sf-fade">
        <slot name="error-message" v-bind="{ errorMessage }">
          <div
            :id="`${nameWithoutWhitespace}-error`"
            :class="{ 'display-none': valid }"
            aria-live="assertive"
          >
            {{ errorMessage }}
          </div>
        </slot>
      </transition>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "InputSelect",
  data() {
    return {
      selectTitle: this.label,
      showDropdown: false,
      termForFilter: "",
    };
  },
  components: {
    Icon,
  },
  props: {
    id: {
      type: String,
      required: true,
      default: () => {
        "";
      },
    },
    name: {
      type: String,
      required: true,
      default: () => {
        "text";
      },
    },
    optionsData: {
      type: Array,
      required: true,
      default: () => {
        [];
      },
    },
    value: {
      required: false,
      default: () => {
        [];
      },
    },
    label: {
      type: String,
      required: false,
      default: () => {
        "Check me";
      },
    },
    required: {
      type: Boolean,
      required: false,
      default: () => {
        false;
      },
    },
    checked: {
      type: Boolean,
      required: false,
      default: () => {
        false;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => {
        false;
      },
    },
    optionToDisplay: {
      type: String,
      required: false,
      default: "",
    },
    optionToSave: {
      type: String,
      required: false,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    valid: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    selectOption(option, title, val) {
      if (this.optionToSave === "region") {
        this.selectTitle = title;
        this.termForFilter = "";
        this.$emit("update:value", {
          region: val.name,
          region_code: val.code,
          region_id: val.id,
        });
        this.$emit("optionUpdated");
        this.showDropdown = false;
      } else {
        this.selectTitle = title;
        this.termForFilter = "";
        this.$emit("update:value", option);
        this.$emit("optionUpdated");
        this.showDropdown = false;
      }
    },
    close(e) {
      if (!this.$refs.toggle.contains(e.target)) {
        this.showDropdown = false;
      }
    },
    nameWithoutWhitespace() {
      return this.name.replace(/\s/g, "");
    },
    doSomething() {
      this.showDropdown = !this.showDropdown;
    },
  },
  computed: {
    filteredData() {
      return this.optionsData.filter((option) => {
        return option[this.optionToDisplay]
          .toLowerCase()
          .includes(this.termForFilter.toLowerCase());
      });
    },
  },
  mounted() {
    window.addEventListener("click", this.close);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.close);
  },
  watch: {
    optionsData: {
      immediate: true,
      handler(val) {
        this.selectTitle = this.label;
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-wrapper {
  position: relative;
  box-sizing: border-box;

  .dropdown-title {
    @apply border border-grey px-6 py-4 text-black relative;
    border-radius: 25px;
    box-shadow: 0 0 1px 1px white;
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    font: normal normal normal 16px/18px "Franklin Gothic Book", sans-serif;
    letter-spacing: 0em;
    text-align: left;
    input {
      @apply leading-6;
      width: 95%;
      background: #fff;
      font: normal normal normal 16px/18px "Franklin Gothic Book", sans-serif;
      @apply font-fgb;
      color: #655a5a;
      &:focus-visible,
      &:focus,
      &:active {
        outline: none;
        border: none;
      }
      &::placeholder {
        font: normal normal normal 16px/18px "Franklin Gothic Book", sans-serif;
        @apply font-fgb;
        color: #655a5a;
      }
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:active,
    input:-webkit-autofill:focus {
      background-color: #fff !important;
      color: #655a5a !important;
      -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
      -webkit-text-fill-color: #655a5a !important;
    }
    &.no-border-radius {
      border-radius: 25px 25px 0 0;
    }
    .icon {
      position: absolute;
      right: 16px;
      bottom: 16px;
      cursor: pointer;
      transition: transform 100ms ease-in;
      .iconify {
        color: #655a5a;
      }
    }
    .rotate {
      transform: rotate(180deg);
      transition: transform 100ms ease-in;
    }
  }

  .label {
    font: normal normal normal 15px/18px Roboto;
    letter-spacing: 0px;
    color: #11322c;
  }

  .dropdown-conent {
    @apply bg-white absolute border border-grey px-4;
    left: 0;
    width: 100%;
    z-index: 2;
    max-height: 300px;
    overflow: auto;
    border-radius: 0 0 5px 25px;
    box-shadow: 0 0 1px 1px white;
    border-top: none;

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 25px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      @apply bg-black;
      border-radius: 5px;
    }

    .dropdown-list-item {
      @apply p-2;
      font: normal normal normal 16px/18px "Franklin Gothic Book", sans-serif;
      color: #655a5a;
      cursor: pointer;
      &:hover {
        @apply bg-white;
      }
    }
  }
}
.variants {
  .variants-items {
    .label {
      font: normal normal normal 16px/18px "Franklin Gothic Book", sans-serif;
    }
    .dropdown-wrapper .dropdown-title,
    .dropdown-conent {
      background: #fff;
      border: 2px solid #d4d4d4;
      input {
        background: #fff;
        border-bottom: none;
      }
    }
    .no-border-radius {
      border-bottom: none !important;
    }
  }
}
</style>
