<template>
  <div
    v="'font-size'"
    class="custom-input"
    :data-testid="nameWithoutWhitespace"
  >
    <div class="custom-input__wrapper relative">
      <!-- <label
        :class="{ 'display-none': !label }"
        class="custom-input__label"
        :for="nameWithoutWhitespace"
      >
        <slot name="label" v-bind="{ label }">{{ label }}</slot>
      </label> -->
      <p
        v-if="fakeInput"
        class="fakeInput"
        :class="{ disabledColor: disabled }"
      >
        {{ value }}
      </p>
      <input
        v-else
        :id="nameWithoutWhitespace"
        v-focus
        v-bind="$attrs"
        :value="value"
        :required="required"
        :disabled="disabled"
        :placeholder="label + (required ? ' *' : '')"
        :name="name"
        :class="{ 'custom-input--is-password': isPassword }"
        :style="{ paddingRight: isPassword ? '30px' : '40px' }"
        :type="inputType"
        :aria-invalid="!valid"
        :aria-required="required"
        :aria-describedby="
          errorMessage ? `${nameWithoutWhitespace}-error` : null
        "
        v-on="listeners"
      />
      <span class="custom-input__bar"></span>
      <slot
        v-bind="{
          icon,
          isPasswordVisible,
          switchVisibilityPassword,
        }"
        name="icon"
      >
        <div v-if="isPassword" @click="switchVisibilityPassword()" class="icon">
          <Icon
            v-if="isPassword"
            height="25"
            :icon="
              isPasswordVisible ? 'mdi:eye-outline' : 'mdi:eye-off-outline'
            "
          />
        </div>
        <div class="icon">
          <Icon
            v-if="value && valid && !isPassword"
            height="25"
            icon="material-symbols:check-small-rounded"
          />
        </div>
        <div class="icon">
          <Icon v-if="!valid && !isPassword" height="25" icon="prime:times" />
        </div>
      </slot>
    </div>
    <div class="custom-input__error-message">
      <transition name="sf-fade">
        <slot name="error-message" v-bind="{ errorMessage }">
          <div
            :id="`${nameWithoutWhitespace}-error`"
            :class="{ invisible: valid }"
            aria-live="assertive"
            key="error"
          >
            {{ errorMessage }}
          </div>
        </slot>
      </transition>
    </div>
  </div>
</template>
<script>
import { focus } from "@storefront-ui/vue/src/utilities/directives";
import { Icon } from "@iconify/vue2";

export default {
  name: "CustomInput",
  directives: {
    focus,
  },
  components: { Icon },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    icon: {
      type: Object,
      default: () => {
        return { icon: "" };
      },
    },
    valid: {
      type: Boolean,
      default: true,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
      description: "Native input required attribute",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Native input disabled attribute",
    },
    hasShowPassword: {
      type: Boolean,
      default: false,
    },
    fakeInput: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPasswordVisible: false,
      inputType: "",
      isNumberTypeSafari: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: (event) => this.$emit("input", event.target.value),
      };
    },
    isPassword() {
      return this.type === "password" && this.hasShowPassword;
    },
    nameWithoutWhitespace() {
      return this.name.replace(/\s/g, "");
    },
  },
  watch: {
    type: {
      immediate: true,
      handler: function (type) {
        let inputType = type;
        // Safari has bug for number input
        if (typeof window !== "undefined" || typeof document !== "undefined") {
          const ua = navigator.userAgent.toLocaleLowerCase();
          if (
            ua.indexOf("safari") !== -1 &&
            ua.indexOf("chrome") === -1 &&
            type === "number"
          ) {
            this.isNumberTypeSafari = true;
            inputType = "text";
          }
        }
        this.inputType = inputType;
      },
    },
    value: {
      immediate: true,
      handler: function (value) {
        if (!this.isNumberTypeSafari) return;
        if (isNaN(value)) {
          this.$emit("input");
        }
      },
    },
  },
  methods: {
    switchVisibilityPassword() {
      if (this.type !== "password") return;
      this.isPasswordVisible = !this.isPasswordVisible;
      this.inputType = this.isPasswordVisible ? "text" : "password";
    },
  },
};
</script>
<style lang="scss">
.custom-input {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__label {
    font: normal normal normal 15px/18px;
    letter-spacing: 0px;
    color: #655a5a;
  }
  input {
    @apply border border-grey px-6 py-4 text-black;
    border-radius: 25px;
    box-shadow: 0 0 1px 1px white;
    margin-top: 7px;
    font: normal normal normal 16px/18px Montserrat;
    &:focus-visible,
    &:focus,
    &:active {
      outline: none;
    }
    &::placeholder {
      font-family: "Montserrat";
      color: #655a5a;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:active,
  input:-webkit-autofill:focus {
    background-color: #fff !important;
    color: #655a5a !important;
    -webkit-box-shadow: 0 0 0 1000px #fff inset !important;
    -webkit-text-fill-color: #655a5a !important;
  }

  input:disabled {
    color: rgb(17, 50, 44, 0.5) !important;
  }

  &__error-message {
    @apply pl-4;
    font-size: 11px;
    color: #da3838;
  }
  .icon {
    position: absolute;
    right: 16px;
    bottom: 16px;
    cursor: pointer;
    .iconify {
      color: #655a5a;
    }
  }
  .fakeInput {
    background: #fff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
    margin-top: 7px;
    color: #655a5a !important;
    font: normal normal normal 16px/18px;
  }
  .disabledColor {
    color: rgb(17, 50, 44, 0.5) !important;
  }
}
</style>
